/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import './fontawesome/scss/fontawesome.scss';


$font:'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', '微软雅黑', Arial, sans-serif;

body,.navigation,.header-navbar{
	font-family: $font;
}

.page-loading-icons>div{
    margin-right: 0.7rem;
    margin-left: 0.7rem;
}
.page-loading-text{
    font-size: 1.5rem;
    margin-top: 1.5rem;
    font-weight: 200;
}
